<template>
  <div class="ticket">
    <!-- 第一部分 -->
    <Head :aircraftTicket="aircraftTicket[0][0]" />
    <PeoList :updateResult.sync="result" :seat="[coupons[chosenCoupon]]" :compliant="compliant"
      :explanationText="explanationText" @explanFinish="explanFinish" />

    <div class="gap"></div>

    <!-- 第二部分 -->
    <van-panel title="选票信息">
      <template #header>
        <div class="panel-head">
          <span class="van-cell__title">选票信息</span>
          <Logo v-if="coupons[chosenCoupon]" :corp="coupons[chosenCoupon].corp" />
        </div>
      </template>
      <van-coupon-cell v-if="coupons[chosenCoupon]" class="chooseTicketBk"
        :title="coupons[chosenCoupon].seatClass+'（￥'+coupons[chosenCoupon].value+'）'"
        :value="'余票：'+(coupons[chosenCoupon].number>0?coupons[chosenCoupon].number:'充足')" @click="showList = true;" />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="退改规则" name="1">
          <div class="rule">
            <van-row gutter="10">
              <van-col class="thead" span="7">时间点</van-col>
              <van-col class="thead" span="5">退票费</van-col>
              <van-col class="thead" span="5">同舱改期</van-col>
              <van-col class="thead" span="7" style="text-align:center">签转</van-col>
            </van-row>
            <van-row gutter="10" v-for="(item,i) in ruleList.rulePointList" :key="i">
              <van-col class="van-cell__label" span="7">{{item.content}}</van-col>
              <van-col class="van-cell__label" span="5">￥{{item.refundFee}}/人</van-col>
              <van-col class="van-cell__label" span="5">￥{{item.modifyFee}}/人</van-col>
              <van-col class="van-cell__label" span="7" style="text-align:center">{{item.modifyStipulate}}</van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-panel>
    <el-tooltip v-if="ruleList.baggage" class="item" effect="dark" :content="ruleList.baggage" placement="top">
      <van-notice-bar left-icon="volume-o" :text="'托运规则：'+ruleList.baggage" />
    </el-tooltip>

    <div class="gap"></div>

    <!-- 第三部分 -->
    <!-- 保险 -->
    <Insurance v-if="config_insurance&&coupons[chosenCoupon]" :updateInsuranceResult.sync="insuranceResult"
      :corp="coupons[chosenCoupon].corp" />
    <div class="gap" v-if="config_insurance&&coupons[chosenCoupon]"></div>
    <!-- 公务票 -->
    <BusinessTicket v-if="config_businessticket" :toList="coupons" :toIndex="chosenCoupon" />

    <!-- 座次类型弹出层 -->
    <van-popup v-model="showList" round position="bottom" class="pick-ticket" @opened="resetPosition">
      <AircraftTicket :coupons="coupons" :chosen-coupon="chosenCoupon" :showList="showList" @change="onChange" />
    </van-popup>

    <div class="gap"></div>

    <!-- 第三部分 -->
    <BodyList :totalPrice="totalPrice" />
    <div class="bottom-gap"></div>
    <!-- 第四部分 -->
    <van-submit-bar button-type="info" :price="totalPrice*100" button-text="提交占座" @submit="onSubmit">
      <template #tip>
        <div v-if="coupons[chosenCoupon]" style="text-align:right">
          （票价{{coupons[chosenCoupon].value}}/人+机建50/人{{0 in insuranceResult?`+保险${insurancePrice}/人`:''}}{{config_serviceprice>0?'+服务费'+config_serviceprice+'/人':''}}{{coupons[chosenCoupon].price.ordernowReturn>0?'- 优惠'+coupons[chosenCoupon].price.ordernowReturn+'/人':''}}）*{{result.length}}人
        </div>
      </template>
    </van-submit-bar>

    <!-- 遮罩层 -->
    <LoadingAnimate v-if="wrapper" :text="'占座中'" />

    <LoadingCircle :show="wrapper1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkSeat, CreateTrainPeoList } from "@/services/user";
import { createOrder, updataTicketDom, checkPrice } from "@/services/aircraft";
import {
  AircraftServiceProvider,
  NeedAircraftInsurance,
  ServicePrice,
  BusinessTicketConfig,
  GoWhere
} from "@/common/config";
import Logo from "@/components/Logo";
import Head from "./components/InfoHead";
import PeoList from "./components/PeoList";
import BodyList from "@/components/InfoList";
import Insurance from "@/components/Insurance";
import AircraftTicket from "@/components/AircraftTicket";
import BusinessTicket from "./components/BusinessTicket";
import LoadingCircle from "@/components/LoadingCircle";
import LoadingAnimate from "@/components/LoadingAnimate";
import { getFlightModifyAndRefundRules } from "@/api/aircraft";

export default {
  components: {
    LoadingCircle,
    LoadingAnimate,
    Head,
    BodyList,
    PeoList,
    Insurance,
    Logo,
    AircraftTicket,
    BusinessTicket
  },
  computed: {
    ...mapGetters([
      "aircraftTicket",
      "personList",
      "travelInfo",
      "aircraftOrderChange"
    ]),
    // 保险费
    insurancePrice: function() {
      let price = 0;
      this.insuranceResult.forEach(element => {
        price += element.salePrice;
      });
      return price;
    },
    // 总票价
    totalPrice: function() {
      if (!this.coupons[this.chosenCoupon]) return 0;
      let price =
        this.coupons[this.chosenCoupon].value +
        50 -
        this.coupons[this.chosenCoupon].price.ordernowReturn; // 机票+机建费-代理优惠
      return (
        price * this.result.length +
        this.insurancePrice * this.result.length +
        this.config_serviceprice * this.result.length
      );
    }
  },
  activated() {
    this.compliant = false;
    this.wrapper = false;
    this.showList = false;
    window.setTitle(this.aircraftTicket[0][0].flightNo);
    this.getSeatList();
    this.fetchRule();
  },
  mounted() {
    window.setTitle(this.aircraftTicket[0][0].flightNo);
  },
  data() {
    return {
      config_gowhere: GoWhere, // 下单成功跳转到哪儿
      config_businessticket: BusinessTicketConfig, // 公务票开关
      config_serviceprice: ServicePrice, // 服务费
      config_insurance: NeedAircraftInsurance, // 保险功能开关
      allCorp: AircraftServiceProvider,
      result: [], // 已选择的人员列表
      chosenCoupon: 0, // 可购列表选中的下标
      coupons: [], // 可购座位列表
      disabledCoupons: [], // 不可购列表
      ruleList: {}, // 退改签规则
      showList: false, // 选座弹出层
      compliant: false, // 超标填表开关
      wrapper: false, // 遮罩层
      wrapper1: false, // 遮罩层菊花瓣
      explanation: null, // 申请信息
      explanation1: "",
      explanationText: [],
      index: null, // 编辑框位置
      activeNames: [], // 折叠面板
      show: false,
      checked: true,
      insuranceResult: [] // 已选保险
    };
  },
  methods: {
    // 超标填表成功
    explanFinish(explanation, explanation1) {
      this.compliant = false;
      if (explanation && explanation1) {
        this.explanation = explanation;
        this.explanation1 = explanation1;
        this.onSubmit(false);
      }
    },
    // 生成两种座位列表
    getSeatList() {
      this.coupons = [];
      this.disabledCoupons = [];
      let minpeo = { stand: { allowBookHighest: 999999 } };
      this.personList.forEach(e => {
        if (parseInt(e.stand.allowBookHighest) < minpeo.stand.allowBookHighest)
          minpeo = e;
      });
      this.aircraftTicket[0].forEach(first => {
        first.flightSeat.forEach((element, i) => {
          element.description = checkSeat(minpeo, element, "aircraft");
          element.corp = first.corp;
          element.name =
            "余票：" + (element.number > 0 ? element.number : "充足");
          element.value = element.price.seatPrice;
          element.number != 0
            ? this.coupons.push(element)
            : this.disabledCoupons.push(element);
        });
      });
      this.coupons.sort((a, b) => a.value - b.value);
      for (let i = 0; i < this.coupons.length; i++) {
        const element = this.coupons[i];
        if (element.corp == this.$route.query.corp)
          return (this.chosenCoupon = i);
      }
    },
    // 选票的钩子
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index >= 0 ? index : this.chosenCoupon;
      this.fetchRule();
    },
    // 拉取退改签规则
    fetchRule() {
      getFlightModifyAndRefundRules({
        bookInfo: this.coupons[this.chosenCoupon].bookInfoStr,
        corp: this.coupons[this.chosenCoupon].corp
      }).then(response => {
        this.ruleList = response.data;
      });
    },
    // 点击提交按钮，该方法仅做一些简单的验证，之后还需要调用合规检查，最后才可以提交占座
    onSubmit: async function(f = true) {
      this.explanationText = [];
      // 是否选人？
      if (this.result.length <= 0) return this.$toast.fail("尚未选择人员");
      // 人员信息完整？
      for (let i = 0; i < this.result.length; i++) {
        let element = this.result[i];
        if (
          !Object.prototype.hasOwnProperty.call(element, 'tel') ||
          !Object.prototype.hasOwnProperty.call(element, 'birthday') ||
          !Object.prototype.hasOwnProperty.call(element, 'gender')
        )
          return this.$toast.fail("人员信息不完整");
      }
      // 超标没？
      if (f) {
        this.result.forEach(element => {
          if (
            !checkSeat(element, this.coupons[this.chosenCoupon], "aircraft")
          ) {
            this.explanationText.push(element);
          }
        });
        if (this.explanationText.length > 0) {
          this.wrapper = false;
          this.compliant = true;
          return;
        }
      }
      // 余额充足？
      this.wrapper1 = true;
      let surplus = await this.$store.dispatch(
        "project/updateAppPrice",
        this.totalPrice
      );
      if (this.travelInfo.isControl == "yes" && !surplus) {
        this.wrapper1 = false;
        return this.$toast.fail("行程限额不足");
      }
      // 变价没？
      let seatInfo = this.coupons[this.chosenCoupon];
      let flight = this.aircraftTicket[0].filter(item => {
        return item.corp == seatInfo.corp;
      });
      flight[0].flightSeat = [seatInfo];
      let flightInfo = await checkPrice({ flightInfo: flight });
      this.wrapper1 = false;
      if (flightInfo.check) {
        // 如果变价
        this.$dialog
          .confirm({
            title: "提示",
            message:
              "您购买的机票发生变价，当前价格" +
              flightInfo.flight[0].flightSeat[0].price.seatPrice +
              "，是否继续支付？"
          })
          .then(() => {
            this.grabSeat(flightInfo.flight); // 下单
          });
      } else this.grabSeat(flightInfo.flight);
    },
    // 抢座
    async grabSeat(flightInfo) {
      // 整理请求参数
      let seatInfo = this.coupons[this.chosenCoupon];
      let personList = CreateTrainPeoList(
        this.result,
        seatInfo,
        this.explanation,
        this.explanation1,
        "aircraft"
      );
      personList.forEach(element => {
        element.AAI = 0 in this.insuranceResult; // 加保险
      });
      let req = {
        // 请求参数
        flightInfo: flightInfo,
        travelUser: personList,
        contactPerson: this.travelInfo.loginUser,
        orderTotal: this.totalPrice.toString(),
        applyNo: this.travelInfo.appId
      };
      // 下单 - 轮询
      this.wrapper = true;
      let ticket1 = await createOrder(req, this.$route.name);
      this.wrapper = false;
      if (ticket1) {
        switch (this.config_gowhere) {
          case "detail": // 转到详情
            this.$router.push({
              path: "/paymentAircraft",
              query: {
                corp: ticket1.corp,
                orderNo: ticket1.orderSerialNo,
                outOrderNo: ticket1.outOrderNo
              }
            });
            break;
          default:
            // 默认转到订单列表
            this.$router.push({ path: "/plan" });
            break;
        }
      }
      // else this.$toast.fail('未获取到票信息，请前往订单页面查找');
    },
    // 重置选票位置
    resetPosition() {
      updataTicketDom();
    }
  }
};
</script>

<style lang="less" src="@/styles/orderCommon.less"></style>
<style lang="less" scoped>
@import "../../styles/theme.less";
.rule {
  padding: 0 16px;
  padding-bottom: 10px;
  .thead {
    font-size: @font-size-md;
    color: @gray-7;
  }
}
.search {
  font-size: @font-size-lg;
}
.content {
  padding: 16px 16px 90px;
}

.text {
  transition: all 2s;
  font-size: @font-size-md;
  color: @gray-7;
  width: 97%;
}
.product {
  color: @blue;
  font-size: @font-size-lg;
}
.bigcheck {
  position: relative;
  top: -10px;
}
.bottom-gap {
  height: 6rem;
}
</style>
<style lang="less">
.rule {
  .van-panel__header {
    padding: 5px 16px;
  }
}
.wrapper .wrapper {
  top: 74% !important;
}
</style>